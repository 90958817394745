/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../../components/Layout'
import ThemeWrapper from '../../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Subtitle, Title, Button, Text, YouTube, Image } from '@swp/components'
import SiteHeader from '../../components/hr/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <SiteHeader />

        <Column className="css-1tat072 --style3 --full" anim={null} name={"intro"} animS={"5"} fullscreen={true} css={css`
        
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/138/fullscreen_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/138/fullscreen_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/138/fullscreen_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/138/fullscreen_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/138/fullscreen_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/138/fullscreen_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/138/fullscreen_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/138/fullscreen_s=3000x_.jpg);
    }
  
      `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" style={{"maxWidth":1400}} columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim2 --anim-s5" anim={"2"} animS={"5"} style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box" content={"<span style=\"color: rgb(255, 255, 255);\">Music band</span>"}>
              </Subtitle>

              <Title className="title-box fs--154 lh--1 mt--10" style={{"maxWidth":""}} content={"<span style=\"color: white;\">The Strings</span><br>"}>
              </Title>

              <Button className="btn-box btn-box--shape5 fs--24 mt--16" content={"Upcoming performances"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--50 pt--60" name={"about-us"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s5 el--2 flex--center" anim={"2"} animS={"5"} style={{"maxWidth":""}} columns={"2"}>
            
            <ColumnWrapper className="pt--10" style={{"maxWidth":550}}>
              
              <Title className="title-box fs--62" content={"The Strings is a traditional jazz band from Prague"}>
              </Title>

              <Text className="text-box" content={"Click and start writing. This is a text area. If needed, the color, font size, line spacing, text alignment and maximum width of a block of text may be customized. Click and start writing. This is a text area. If needed, the color, font size, line spacing, text alignment and maximum width of a block of text may be customized. Click and start writing. This is a text area. If needed, the color, font size, line spacing, text alignment and maximum width of a block of text may be customized.Click and start writing. This is a text area. If needed, the color, font size, line spacing, text alignment and even the <strong>maximum width of a block of text</strong> may be customized. "}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="pt--10" style={{"maxWidth":800}}>
              
              <YouTube embedId={""}>
              </YouTube>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60" name={"about-us-2"}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s5 --center el--4 pb--10" anim={"2"} animS={"5"} columns={"4"}>
            
            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/i/template/138/img-1_s=350x_.jpg"} svg={false} ratio={"3:4"} sizes={"100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/138/img-1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/138/img-1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/138/img-1_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/138/img-1_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/138/img-1_s=2000x_.jpg 2000w"} content={null}>
              </Image>

              <Subtitle className="subtitle-box" content={"Jiří Stloukal"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":650}} content={"Click and start writing. This is a text area. If needed, the color, font size, line spacing, text alignment and even the <strong>maximum width of a block of text</strong> may be customized.  If you wish to find out more about editing, click <a href=\"https://saywebpage.com/cs/video-pruvodce-editaci/\">here</a>."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/i/template/138/img-2_s=350x_.jpg"} svg={false} ratio={"3:4"} sizes={"100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/138/img-2_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/138/img-2_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/138/img-2_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/138/img-2_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/138/img-2_s=2000x_.jpg 2000w"} content={null}>
              </Image>

              <Subtitle className="subtitle-box" content={"Peter Domanický"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":650}} content={"Click and start writing. This is a text area. If needed, the color, font size, line spacing, text alignment and even the <strong>maximum width of a block of text</strong> may be customized.  If you wish to find out more about editing, click <a href=\"https://saywebpage.com/cs/video-pruvodce-editaci/\">here</a>."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/i/template/138/img-3_s=350x_.jpg"} svg={false} ratio={"3:4"} sizes={"100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/138/img-3_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/138/img-3_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/138/img-3_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/138/img-3_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/138/img-3_s=2000x_.jpg 2000w"} content={null}>
              </Image>

              <Subtitle className="subtitle-box" content={"Peter Nemec"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":650}} content={"Click and start writing. This is a text area. If needed, the color, font size, line spacing, text alignment and even the <strong>maximum width of a block of text</strong> may be customized.  If you wish to find out more about editing, click <a href=\"https://saywebpage.com/cs/video-pruvodce-editaci/\">here</a>."}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/i/template/138/img-2_s=350x_.jpg"} svg={false} ratio={"3:4"} sizes={"100vw"} srcSet={"https://cdn.swbpg.com/t/i/template/138/img-2_s=350x_.jpg 350w, https://cdn.swbpg.com/t/i/template/138/img-2_s=660x_.jpg 660w, https://cdn.swbpg.com/t/i/template/138/img-2_s=860x_.jpg 860w, https://cdn.swbpg.com/t/i/template/138/img-2_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/i/template/138/img-2_s=2000x_.jpg 2000w"} content={null}>
              </Image>

              <Subtitle className="subtitle-box" content={"Alena Nováková"}>
              </Subtitle>

              <Text className="text-box" style={{"maxWidth":650}} content={"Click and start writing. This is a text area. If needed, the color, font size, line spacing, text alignment and even the <strong>maximum width of a block of text</strong> may be customized.  If you wish to find out more about editing, click <a href=\"https://saywebpage.com/cs/video-pruvodce-editaci/\">here</a>."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="css-1tat072 pb--60 pt--60" name={"our-work"} css={css`
        
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/138/fullscreen_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/138/fullscreen_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/138/fullscreen_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/138/fullscreen_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/138/fullscreen_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/138/fullscreen_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/138/fullscreen_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/138/fullscreen_s=3000x_.jpg);
    }
  
      `}>
          
          <ColumnWrap className="column__flex js-anim  --anim2 --anim-s5 --center el--1 flex--center" anim={"2"} animS={"5"} style={{"maxWidth":500}} columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Title className="title-box fs--62" content={"<span style=\"color: rgb(255, 255, 255);\">Listen to our songs</span><br>"}>
              </Title>

              <Button className="btn-box btn-box--shape5 fs--22" content={"Our Work"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--50 pt--50" name={"footer"} style={{"backgroundColor":"var(--color-supplementary)"}}>
          
          <ColumnWrap className="column__flex el--2 flex--center" anim={""} animS={"5"} style={{"maxWidth":""}} columns={"2"}>
            
            <ColumnWrapper className="pt--10" style={{"maxWidth":360}}>
              
              <Subtitle className="subtitle-box w--900" content={"<span style=\"color: var(--color-dominant);\">The Strings</span>"}>
              </Subtitle>

              <Text className="text-box" content={"<span style=\"color: var(--color-blend--95);\">Click and start writing. This is a text area. If needed, the color, font size, line spacing, text alignment and even the <strong>maximum width of a block of text</strong> may be customized.  If you wish to find out more about editing, click <a href=\"https://saywebpage.com/cs/video-pruvodce-editaci/\">here</a>.</span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--right pt--10" style={{"maxWidth":800}}>
              
              <Subtitle className="subtitle-box w--900" content={"<span style=\"color: var(--color-dominant);\">Contact</span>"}>
              </Subtitle>

              <Text className="text-box" content={"<span style=\"color: var(--color-blend--95);\">510-851-4014<br>info@vase-stranky.com<br>Created with <a href=\"https://saywebpage.com\">saywebpage.com</a></span><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}